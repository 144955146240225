import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import mediaimage from "../assets/imagenew/medianews.jpeg";
// import mediaimage1 from "../assets/imagenew/mediaimage1.jpeg";
// import mediaimage2 from "../assets/imagenew/mediaimage2.jpeg";
import { Helmet } from "react-helmet";
function Media() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://saradminnew.brandbell.in/public/api/media")
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData.data);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title> Media Solutions | SAR Televenture </title>
        <link rel="canonical" href="https://sarteleventure.com/Media" />
        <meta
          name="keywords"
          content="Home Automation Solutions, Home Automation Company, Energy Management, Prepaid Meter, Smart CCTV Solutions, Intercom Service Provider, Smart CCTV Solutions In Delhi NCR, Automatic Boom Barrier Solutions, Video Door Phone System, Home Automation Company In Delhi NCR, FTTH, FTTX, GPON, Fire Alarm Company In Delhi NCR, Fire Alarm System, Intercom Service Provider In Delhi NCR"
        />
        <meta
          name="description"
          content=" SAR Televenture delivers innovative media solutions that enhance communication and engagement, tailored to meet your business needs effectively."
        />
        <meta name="title" content=" Media Solutions | SAR Televenture " />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sarteleventure.com/" />
        <meta
          property="og:image"
          content="https://sarteleventure.com/path_to_image.jpg"
        />
        <meta
          property="og:description"
          content="SAR Televenture offers advanced FTTH solutions, providing high-speed and reliable fiber optic technology for modern telecommunications."
        />
        <meta property="og:site_name" content="SAR Televenture" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:updated_time" content="2024-04-19T00:00:00Z" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="https://sarteleventure.com/" />
        <meta
          name="twitter:description"
          content="SAR Televenture is the top fire alarm system and fire detection system company - designing and offering a variety of fire alarm systems.."
        />
        <meta
          name="twitter:image"
          content="https://sarteleventure.com/assets/imagenew/para-logo.png"
        />
      </Helmet>

      <div class="case-study-area pt-100 pb-70 w-full">
        <div class="container w-full mx-auto">
          <div className="section-title text-center z-10 flex  center flex-col items-center lg:ml-[250px]">
            <h2 className="leading-3 text-black " style={{ fontSize: "25px" }}>
              Check Recent Media article
            </h2>
          </div>
          <div class="row pt-45">
            {data.map((media, index) => (
              <div class="col-lg-4 col-md-6" key={index}>
                <div class="case-study-item">
                  <Link to="">
                    <img
                      src={`https://demo.digitalprisma.in/public//storage/media/${media.image}`}
                      alt="mediaimage"
                    />
                  </Link>
                  <div class="content">
                    <h3>
                      <Link to="" style={{ fontSize: "20px" }}>
                        {media.title}
                      </Link>
                    </h3>
                    <ul>
                      <li>
                        <Link to="">Mar 07, 2024</Link>
                      </li>
                      <li>
                        <Link to="">04:04:00 PM</Link>
                      </li>
                    </ul>
                    <Link target="_blank" to={media.link} class="more-btn">
                      <i class="bx bx-right-arrow-alt"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
